<template>
    <div>
        <Header></Header>
        <div class="w-full">
            <router-view></router-view>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';

    export default {
        name: 'PressRoom',

        components: {
            Header,
            Footer
        }
    };
</script>
